.container {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .container * {
    box-sizing: border-box;
  }
  
  .flex-outer,
  .flex-inner {
    list-style-type: none;
    padding: 0;
  }
  
  .flex-outer {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .flex-outer li,
  .flex-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .flex-inner {
    padding: 0 8px;
    justify-content: space-between;  
  }
  
  .flex-outer > li:not(:last-child) {
    margin-bottom: 20px;
  }
  
  .flex-outer li label,
  .flex-outer li p {
    padding: 8px;
  }
  
  .flex-outer > li > label,
  .flex-outer li p {
    flex: 1 0 120px;
    max-width: 220px;
  }
  
  .flex-outer > li > label + *,
  .flex-inner {
    flex: 1 0 220px;
  }
  
  .flex-outer li p {
    margin: 0;
  }
  
  .flex-outer li input:not([type='checkbox']),
  .flex-outer li textarea {
    padding: 15px;
    border-radius: 11px;
    border-color: #8934eb;
    border-style: solid;
    border-width: 2px;
  }
  
  .flex-outer li button {
    margin-left: auto;
    padding: 11px 25px;
    border: none;
    background: #8934eb;
    color: #f2f2f2;
    border-radius: 11px;
  }

  .flex-inner li {
    width: 100px;
  }

  .notification {
    border-style: solid;
    border-radius: 11px;
    background: #8934eb;
    border-color: #8934eb;
    margin-bottom: 20px;
    padding: .5em;
    color:white;
    text-align: center;
  }

 