.Wrapper {
  display: grid;
  grid-auto-rows: minmax(100px, auto);
}

.App-header {
  border-bottom:#8934eb;
  border-bottom-style: solid;
  text-align: center;
  margin-top:50px;
  padding-bottom: 3em;
  text-transform: uppercase;
}

.App-body {
  padding: 1em;
}

.App {
  text-align: center;
}
